.cta-button {
  -webkit-appearance: none;
  font-weight: 400;
  text-decoration: none;
  padding: 8px 16px;
  margin: 16px;
  border-radius: 0.6em;
  border-style: solid;
  background-color: var(--action-color);
  border-color: var(--action-color);
  color: white;
  font-size: 19px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}
