.hero-image {
  background-image: url('/images/DSC00211-Enhanced-NR-6_MD.jpeg'); /* Reference your uploaded image */
  background-size: cover;
  background-position: center;
  height: 100vh; /* Full viewport height for the hero section */
  @media (max-width: 460px), (max-height: 677px) { /* this is how you write an OR clause rule */
    height: auto;

    // custom styles at this breakpoint
    .custom-w-full {
      width: 100%; /* Full width for small screens */
    }
    .custom-h-full {
      height: 100%; /* Full width for small screens */
    }
  }

  @media (max-width: 460px) {
    background: none;
  }
}

.hero-container {
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: -80px;
  position: relative;
  width: auto;
  height: 425px;
  @media (max-width: 500px) {
    margin-bottom: -100px;
  }

  .hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-overlay {
    width: 100%;
    position: absolute;
    top: 30%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for true center */

    header {
      width: 100%;
      h1 {
        font-size: 3.75rem;
        margin: 0;
        color: white;
        @media (max-width: 500px) {
          font-size: 3.25rem;
          margin: 0.5rem;
        }
      }

      a {
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.14), -2px -2px 4px rgba(0, 0, 0, 0.12);
        color: inherit;
        text-decoration: none;
        width: 100%;
      }
    }

    nav {
      ul {
        color: white;
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: center;
        gap: 1.5rem;
        font-size: 22px;
        font-weight: 600;

        @media (max-width: 500px) {
          margin: 1rem 0;
        }
      }

      a {
        color: inherit;
        text-decoration: none;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.14), -2px -2px 4px rgba(0, 0, 0, 0.12);
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
