.posts {
  padding: 0;
  li {
    list-style-type: none;
    padding-bottom: 5px;
  }
}

.post {
  .tags {
    display: flex;
    justify-content: center;
    margin-top: -38px;
    margin-bottom: 50px;
    a {
      color: #bbb;
      text-decoration-color: #bbb;
      &:not(:first-child) {
        padding-left: 5px;
      }
    }
  }

  // make post subheaders into hash links
  .action-header {
    color: var(--action-color);
  }
  h2, h3, h4 {
    width: fit-content;
    &:hover {
      cursor: pointer;
    }
  }
  h3, h4 {
    margin-bottom: -6px;
  }
}
