@use "utilities";
@use "components";
@use "posts";
@use "home";

:root {
  // using the Zinc color palate from tailwind
  --body-background: #f4f4f5;
  --body-color: #3f3f46;
  --heading-color: #18181b;
  --action-color: #d64045;
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: "Figtree", sans-serif;
  margin: 0 8px;
  line-height: 1.4;
  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
}

h1, h2, h3, h4, h5 {
  font-family: "PT Serif", serif;
  font-weight: 700;
  font-style: normal;
}

a {
  color: var(--action-color);
  text-decoration: underline;
  text-decoration-color: var(--action-color);
  &:hover {
    cursor: pointer;
  }
}

h1 {
  margin: 1rem 0 3rem;
  text-align: center;
  font-weight: 900;
  font-size: 2.5rem;
  color: var(--heading-color);
  line-height: 1.2;
}

.emoji-header {
  font-size: 80px;
  @media (max-width: 600px) {
    font-size: 60px;
  }
}

// styles for site title header
body > header {
  margin: 1rem;
  text-align: center;
  @media (max-width: 500px) {
    margin: 1rem;
  }

  a {
    text-decoration: none;
    width: 100%;
    color: inherit;
  }

  h1 {
    font-size: 3.75rem;
    margin: 0;
    @media (max-width: 500px) {
      font-size: 3.25rem;
      margin: 0.5rem;
    }
  }
}

// styles for main nav
body > nav ul {
  margin: -0.2rem 0 1.2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 22px;
  font-weight: 600;

  @media (max-width: 500px) {
    margin: 1rem 0;
  }
}

// styles for nav links
body > nav a {
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
}

// styles for main content card
main {
  position: relative;
  margin: 1rem auto 3rem;
  max-width: 46rem;
  min-height: calc(70vh - 200px);
  padding: 8px 45px 25px 45px;
  background: white;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  text-align: justify;
  @media (max-width: 390px) {
    text-align: center;
  }

  @media (max-width: 500px) {
    padding: 8px 19px;
    min-height: calc(85vh - 200px);
    margin-bottom: 2rem;
  }
}

// styles for site footer
footer {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 1em;

  @media (max-width: 500px) {
    margin-bottom: 2rem;
  }
}

hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3rem 0;
}

.pagination {
  padding: 0;
  list-style-type: none;
  display: flex;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;

  .previous-page,
  .next-page {
    margin: 0 5px;
    a {
      text-decoration: none;
    }
  }
}

.photo-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;

  .photo-container {
    flex-basis: 360px; /*width: 400px;*/
    position: relative;

    @media (max-width: 896px) {
      flex-basis: 325px; /*width: 325px;*/
    }

    .photo {
      object-fit: cover;
      max-width: 100%;
      height: auto;
      vertical-align: middle;

      box-shadow: 0 3px 6px #ddd, 0 3px 6px #ddd;
    }
  }
}

.videos {
  .video-gallery {
    margin-top: -35px;
    padding: 20px;
    @media (max-width: 500px) {
      padding: 5px;
      margin-top: -25px;
    }
    .video-container {
      position: relative;
      display: block;
      overflow: hidden;
      height: 0px;
      padding-bottom: 56.1%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    }
  }
}

.about {
  .headshot {
    margin-top: -15px;
    margin-bottom: 35px;
  }

  .hide-on-small {
    @media (max-width: 500px) {
      display: none;
    }
  }
}
